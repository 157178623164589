<template>
	<div class="horizontalScreen" id="horizontalScreen">
		<van-row id="horizontalScreenRow" style="position:relative;">
			<!-- <button id="saveBtn2" class="savaBtn saveBtn2 btn btn-default" onclick="saveSignature();" disabled>完成签字</button> -->
			
			<van-col span="21">
				<div class="signwrap" id="signwrapHori">
					<div class="signwrap2p">
						<p>一笔一画签署本人姓名，请勿连笔</p>
					</div>
				</div>
				<div class="js-signature" data-width="300" data-height="600" data-border="1px solid #eee" data-background="rgba(249, 249, 249,0)" data-auto-fit="true">
					
				</div>
			</van-col>
			<van-col span="1">
				<div>
					<img class="imgclear2" @click="clearCanvas" src="@/assets/img/clear2.png"/>
					<p class="customer"><span>客</span><span>户</span><span>签</span><span>名</span></p>
					<van-button id="saveBtn" class="savaBtn" @click="saveSignature();" :disabled='isDisabled'>确认</van-button>
					<!-- <img class="imgnarrow" id="imgnarrow" src="@/assets/img/narrow.png"/> -->
				</div>	
			</van-col>
			
		</van-row>
		<div class="van-overlay" style="z-index: 2003" v-if='showDialog'></div>
		<div class="dialogBox" v-if='showDialog'>
			<div class="dialogText">{{dialogText}}</div>
			<div class="dialogBottom">
				<div class="dialogBtn1" @click="dialogCancel">取消</div>
				<div class="dialogBtn2" @click="dialogConfirm">确认</div>
			</div>
		</div>
		<div v-if='showToastSuccess' class="van-toast van-toast--middle van-toast--success" style="z-index: 2001;">
			<i class="van-icon van-icon-success van-toast__icon"><!----></i>
			<div class="van-toast__text">签字成功！</div>
		</div>
		<div v-if='showToastFail' class="van-toast van-toast--middle van-toast--fail" style="z-index: 2001;">
			<i class="van-icon van-icon-fail van-toast__icon"><!----></i>
			<div class="van-toast__text">{{toastText}}</div>
		</div>
		<!-- <div class="js-signature" data-width="600" data-height="200" data-border="1px solid black" data-line-color="#bc0000" data-auto-fit="true"></div> -->
	</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { Image as VanImage } from 'vant';
import {compoundContract } from '@/server';
import $ from 'jquery';
import '@/lib/jq-signature.js';
import { getCookie } from '@/lib/utils'
export default {
  name: 'sign',
	data() {
		return {
			phone:'',
			dataUrl:'',
			signImgUrl:'',
			imgUrl:'',
			isDisabled:true,
			token:getCookie('TOKEN'),
			dialogText: '签字确认',
			showDialog: false,
			dataBase:'',
			showToastSuccess:false,
			showToastFail:false,
			toastText:''
		}
	},
	mounted() {
		sessionStorage.setItem('key', 'card5');
		let that = this;
		window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
		if(sessionStorage.getItem('key') == 'card5') {
        window.history.pushState('forward', null, '/transferAuthorization');
      }
		// if(that.$route.path == '/transferAuthorization'){
		// 	window.history.pushState('forward', null, '/transferAuthorization');
		// }
		})
    document.title = '客户签字'
		document.getElementById("horizontalScreen").addEventListener('touchmove', function(e){
			e.preventDefault();
		}, { passive: false });
		if($('.js-signature').length) {
			$('.js-signature').jqSignature();
		};
		$('.js-signature').on('jq.signature.changed', function() {
			that.isDisabled = false;
		});
		this.getDetail();
  },
	methods: {
		getDetail(){
			var w = $(window).width();	
			var h = $(window).height();
			// clearCanvas();
			$(".js-signature").attr({"data-height":(h-50)+"px"});
			$(".js-signature").attr({"data-width":(w-90)+"px"});
			$("canvas").attr({"width":(w-90)+"px"});
			$("canvas").attr({"height":(h-50)+"px"});
			$("canvas").css({"height":(h-50)+"px"});
			$("#signwrapHori").css({"height":(h-50)+"px"});
			$("canvas").css({"width":(w-90)+"px"});
			$("#signwrapHori").css({"width":(w-90)+"px"});
			$("canvas").css({"borderRadius":"15px"});
		},
		saveSignature() {
			// var dataBase;
			var that = this;
			this.imgUrl = $('.js-signature').jqSignature('getDataURL');
//			var dataBase = $('.js-signature').jqSignature('getDataURL').substring(22);
			this.rotateBase64Img(this.imgUrl, 270, function(base64data) {
				that.signImgUrl = base64data;
				that.dataBase = base64data.substring(22);
			});
			this.showDialog = true;		
		},
		dialogConfirm(){
			this.showDialog = false
			var that = this;
			let token = localStorage.getItem('token')||getCookie('token');
			$.ajax({
				headers: {
					"Authorization": "Bearer "+token
				},
				url: '/oraflfile/upload/content',
				data:{"group":"1","name":"12.png","content":that.dataBase},
				type: "POST",
				success: function (res) {
					if(res.status == 200){
						that.dataUrl = res.data;
						that.contractSign();
					}else{
						that.clearCanvas();
						that.showToastFail = true;
						that.toastText = res.msg;
						setTimeout(()=>{
							that.showToastFail = false;
						},3000)	
					}
				}
			})
		},
		dialogCancel(){
			this.showDialog = false;
			// this.clearCanvas();
		},
		contractSign(){
			const dataList = this.$store.state.authorData;
			console.log(dataList)
			const customerInfo = {
				name:dataList.customername,
				mobile:dataList.mobile,
				cardNo:dataList.cardno
			}
			const addressList = [{
				templateKey:dataList.addressList[0].templateCode,
				tempUrl:dataList.addressList[0].address
			}]
			const params = {
				lessType:'0',
				customerInfo,
				addressList,
				cusSign:this.dataUrl
			}
			compoundContract(params).then((res) => {
				var errorMsg = res.subMsg==undefined ? res.msg : res.subMsg;
				const{data}=res;
				if(res.status==200){
					this.showToastSuccess = true;
					this.toastText = errorMsg;
					setTimeout(()=>{
						this.showToastSuccess = false;
					},3000)
					let authorData = this.$store.state.authorData;
					authorData.sign_status = '已签约';
					authorData.signimg_url = data.list[0].address;
					const contractNum = authorData.contract_number;
					this.$store.commit('authorData', authorData);
					this.$store.commit('writeData', authorData);
					this.$store.commit('backFlagNum', contractNum);
					this.$store.commit('backFlag','1');
					this.$router.push({path: '/modifyDebitCard'})
				}else{
					this.showToastFail = true;
					this.toastText = errorMsg;
					setTimeout(()=>{
						this.showToastFail = false;
					},3000)	
					// Toast.fail({
          //   message: errorMsg,
          //   duration:'3000'
					// });
					this.clearCanvas();
				}
			})
		},
		clearCanvas() {			
			$('.js-signature').jqSignature('clearCanvas');
			this.isDisabled=true;
		},
		//图片旋转
		rotateBase64Img(src, edg, callback) {
			var canvas = document.createElement("canvas");
			var ctx = canvas.getContext("2d");
			var imgW;//图片宽度
			var imgH;//图片高度
			var size;//canvas初始大小
			if (edg % 90 != 0) {
				console.error("旋转角度必须是90的倍数!");
				throw '旋转角度必须是90的倍数!';
			}
			(edg < 0) && (edg = (edg % 360) + 360)
			const quadrant = (edg / 90) % 4; //旋转象限
			const cutCoor = {sx: 0, sy: 0, ex: 0, ey: 0}; //裁剪坐标
			var image = new Image();
			image.crossOrigin = "anonymous"
			image.src = src;
			image.onload = function () {
				imgW = image.width;
				imgH = image.height;
				size = imgW > imgH ? imgW : imgH;
				canvas.width = size * 2;
				canvas.height = size * 2;
				switch (quadrant) {
					case 0:
						cutCoor.sx = size;
						cutCoor.sy = size;
						cutCoor.ex = size + imgW;
						cutCoor.ey = size + imgH;
						break;
					case 1:
						cutCoor.sx = size - imgH;
						cutCoor.sy = size;
						cutCoor.ex = size;
						cutCoor.ey = size + imgW;
						break;
					case 2:
						cutCoor.sx = size - imgW;
						cutCoor.sy = size - imgH;
						cutCoor.ex = size;
						cutCoor.ey = size;
						break;
					case 3:
						cutCoor.sx = size;
						cutCoor.sy = size - imgW;
						cutCoor.ex = size + imgH;
						cutCoor.ey = size + imgW;
					break;
				}
				ctx.translate(size, size);
				ctx.rotate(edg * Math.PI / 180);
				ctx.drawImage(image, 0, 0);
				var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
				if (quadrant % 2 == 0) {
					canvas.width = imgW;
					canvas.height = imgH;
				} else {
					canvas.width = imgH;
					canvas.height = imgW;
				}
				ctx.putImageData(imgData, 0, 0);
				callback(canvas.toDataURL())
			};
		}
	},
};
</script>
<style lang='less' scoped>
 * { touch-action: pan-y; }
.horizontalScreen{
  /* height: 100vh !important; */
  padding: 20px;
}
.signwrap{
	position: absolute;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: -1;
	background-color: #f9f9f9;
	border-radius:15px;
	text-align: center;
}
/* .signwrap p{font-size: 1rem; } */
.signwrap2p{
	transform:rotate(90deg);
	color: #ccc;
	font-size: 16px;
}
.imgclear2{
	position: absolute;
	right: 0px;
	width:40px;
	transform: rotate(90deg);
}
.savaBtn{
	transform:rotate(90deg);/* transform-origin:right top; */
	position: absolute;
	/* width: 100px; */
	border: 0px;
	color: #333;
	font-size: 18px;
	bottom: 8px;
	right: -8px;
	/* text-align: right; */
}
.customer{
	position: absolute;
	top: 50%;
	font-size: 18px;
  margin-top: -48px;
	right: 18px;
}
.customer span{
	transform: rotate(90deg);
	display: block;
}
.van-toast{
	transform: rotate(90deg);
	left: 30% !important;
	top: 42%;
}
</style>
<style>
/* .van-dialog{
	transform: rotate(90deg) !important;
  -webkit-transform: rotate(90deg) !important;
	left: 8% !important;
  top: 42%;
} */
/* .van-toast{
	transform: rotate(90deg);
	left: 30% !important;
	top: 42%;
} */
</style>
<style>
#app{
  background: rgba(0,0,0,0) !important;
}
.horizontalScreen .van-empty__bottom{
  margin-top: 0px;
}
.dialogBox{
	transform: rotate(90deg) !important;
  -webkit-transform: rotate(90deg) !important;
	left: 8% !important;
  top: 42%;
	position: fixed;
	top: 45%;
	/* left: 50%; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 320px;
	overflow: hidden;
	font-size: 16px;
	background-color: #fff;
	border-radius: 16px;
	z-index: 2004;
}
.dialogText{
	font-weight: 500;
	line-height: 24px;
	text-align: center;
	padding: 24px 0;
	font-size: 16px;
	color: #323233;
}
.dialogBottom{
	display: flex;
	width: 320px;
	border-top: 1px solid #eeeeee;
}
.dialogBtn1{
	flex: 1;
  height: 48px;
	font-size: 16px;
	color: #323233;
	font-size: 16px;
	text-align: center;
	line-height: 48px;
	border-right: 1px solid #eeeeee;
}
.dialogBtn2{
	color: #ee0a24;
	flex: 1;
  height: 48px;
	font-size: 16px;
	font-size: 16px;
	text-align: center;
	line-height: 48px;
}
</style>